export default [
  {
    title: 'Pages',
    icon: 'HomeIcon',
    children: [
      {
        title: 'Document Category',
        route: 'doccategory',
        icon: 'FileIcon',
      },
      {
        title: 'Documents',
        route: 'documents',
        icon: 'FileIcon',
      },
      {
        title: 'MNO Centre',
        route: 'mnocentre',
        icon: 'FileIcon',
      },
      {
        title: 'Users',
        route: 'users',
        icon: 'FileIcon',
      },
      {
        title: 'Years',
        route: 'years',
        icon: 'FileIcon',
      },
    ],
  },
  {
    header: 'Home',
    icon: 'FileIcon',
    children: [
      {
        title: 'Home',
        route: 'home',
        icon: 'HomeIcon',
      },
      {
        title: 'Second Page',
        route: 'second-page',
        icon: 'FileIcon',
      },
      {
        title: '2021',
        route: 'page-2021',
        icon: 'FileIcon',
      },
      {
        title: 'УП-6195',
        route: 'completed',
        icon: 'FileIcon',
      },
      {
        title: 'SubCompleted',
        route: 'subcompleted',
        icon: 'FileIcon',
      },
      {
        title: 'Пункт 1',
        route: 'punktone',
        icon: 'FileIcon',
      },
      {
        title: 'Project List',
        route: 'project-list',
        icon: 'FileIcon',
      },
    ],
  },
]
